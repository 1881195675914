import moment from "moment";

class Message {

    id: string;
    sender: string;
    nickname: string;
    content: string;
    isImage: boolean;
    datetime_utc: string;
    datetime_utc_moment: any;
    datetime_format: string = "YYYY-MM-DD h:mm:ss";

    constructor(sender: string, nickname: string, content: string, datetime_iso: string, id?: string, isImage: boolean = false) {

        this.sender = sender;
        this.nickname = nickname;
        this.content = content;
        this.id = id ? id : "-1";
        this.isImage = isImage;

        this.datetime_utc_moment = moment.utc(datetime_iso)
        this.datetime_utc = this.datetime_utc_moment.format("YYYY-MM-DD h:mm:ss");

    }

    // getLocalDateTime() : string { console.log(moment.locale()); return moment.utc(this.datetime_utc).local().calendar(); }

    getDate() : string { return this.datetime_utc_moment.local().format("dddd, M/DD/YY"); }

    getTime() : string { return this.datetime_utc_moment.local().format("h:mm A"); }

    getDateTime() : string { return `${this.getDate()} ${this.getTime()}`; }

    getElapsedDateTime() : string {
        
        const today = moment.utc(new Date());
        // If the message was sent today, return only the time
        if (this.datetime_utc_moment.isSame(today, "day")) {

            return this.getTime();

        }
        // If it was yesterday, display calendar() format
        if (this.datetime_utc_moment.isSame(today.subtract(1, "day"), "day")) {

            return this.datetime_utc_moment.local().calendar();

        }
        // If the message was sent any other day, get the full date and time
        return this.getDateTime();
    
    }

    getMinuteDiff(date: any) : number { return Math.abs(this.datetime_utc_moment.diff(date, "minutes")); }

    isDaySame(date: any) : boolean { return this.datetime_utc_moment.isSame(date, "day"); }
    
}

export default Message;