import styles from './ChatItem.module.css';
import Message from "../../../Models/Message";
import React, { useCallback, useState } from 'react';

interface IProps {
  
  server: string | undefined,
  index: number,
  message: Message,
  senderClass: string,
  hasProfile: boolean,
  hasDate: boolean,
  scrollIntoView: boolean,
  selectedIdx: number,
  thisIdx: number

}

const linkRegEx = new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/)

const ChatItem = (props: IProps) => {  

  const senderClass = getSenderClassStyle(props.senderClass);
  const [message] = useState(props.message);
  const [showing, setShowing] = useState<boolean>(false);
  const [scrollIntoView, setScrollIntoView] = useState<boolean>(props.scrollIntoView);
  const [selectedIdx] = useState<number>(props.selectedIdx);
  const [thisIdx] = useState<number>(props.thisIdx);
  const ref = React.useRef<HTMLDivElement | null>(null);

  // const ref = React.useRef<null | HTMLDivElement>(null);

  const fullscreen = useCallback(() => {

    document.getElementById("imageContainer")?.classList.add(styles.Fullscreen);
    document.getElementById(`overlay`)?.classList.add(styles.Collapsed);
    document.getElementById(`close`)?.classList.add(styles.Visible);

  }, []);

  const unFullscreen = useCallback(() => {

    document.getElementById("imageContainer")?.classList.remove(styles.Fullscreen);
    document.getElementById(`overlay`)?.classList.remove(styles.Collapsed);
    document.getElementById(`close`)?.classList.remove(styles.Visible);

  }, []);

  const setRef = useCallback((node: HTMLDivElement) => {

    if (node != null) {

      ref.current = node;
      if (scrollIntoView) {

        node.scrollIntoView({ behavior: "smooth" });

      }

    }

  }, [scrollIntoView]);

  return (
    <div className={`${styles.ChatItem}`} ref={thisIdx === selectedIdx ? setRef : null}>

      {/* If this date is different from the previous one, show the date */}
      { props.hasDate &&
      <div className={styles.OverallDate}>
        {message.getDate()}
      </div>}

      {/* Shows username if this Message's sender is different from the previous Message's sender
          Or if more than 10 minutes have passed since the last message */}
      { props.hasProfile &&
      <div className={`${styles.ProfileContainer} ${senderClass}`}>

        {/* If sender, date comes first, otherwise second */}
        <span className={styles.Profile}>{message.nickname}</span>
        <span className={styles.ProfileDate}>{message.getElapsedDateTime()}</span>

      </div> }

      {/* Shows the Message text */}
      <div className={`${styles.MessageContainer} ${senderClass} ${scrollIntoView ? styles.Blink : ""}`}>
        { message.isImage
          // Is an Image
            ? <div className={`${styles.MessageText}`} style={{color: "red", fontSize: "16px", cursor: "default"}}>
                ! Image Unavailable !
              </div>
          // ? showing
          //   // Image is showing
          //   ? <div id="imageContainer" className={styles.ImageContainer}>
          //       <img className={`${styles.MessageImage}`} alt="" src={`${props.server}/image/${message.content}`} />
          //       <div id={`overlay`} className={styles.ImageOverlay} onClick={() => fullscreen()}>
          //         <span style={{backgroundColor: "black", padding: "2px 5px", borderRadius: "10px", }}>Fullscreen</span>
          //       </div>
          //       <div id={`close`} className={styles.CloseImage} onClick={() => unFullscreen()}>X</div>
          //     </div>
          //   // Image is not showing
          //   : <div className={`${styles.MessageText}`} style={{color: "#1499eb", fontSize: "20px"}} onClick={e => { e.preventDefault(); setShowing(true)}}>
          //       <span style={{color: "red", fontSize: "16px"}}>! </span>
          //       Image Message (Click to Show)
          //       <span style={{color: "red", fontSize: "16px"}}> !</span>
          //     </div>
          // Is not an Image
          : <div className={`${styles.MessageText}`}>
              { message.content.match(linkRegEx) !== null
                // Is a Link
                ? <a href={`${message.content}`} target="_blank" rel="noreferrer">{formatContent(`${message.content}`)}</a>
                // Is not a Link
                : formatContent(`${message.content}`)
              }
            </div>
        }

        {/* Message Date/Time */}
        <div className={`${styles.MessageDate} ${senderClass}`}>{message.getDateTime()}</div>
      </div>


    </div>
  )
};

function getSenderClassStyle(senderClass: string) {

  if (senderClass === "Sender") { return styles.Sender; }
  else { return styles.Receiver; }

}

function formatContent(c: string) {

    const strings: Array<string> = c.split("\n");
    const last = strings[strings.length - 1];

    return strings.map((s, i) => {

      return (
        <div key={i}>
          { s }
          { s === last && <br /> }
        </div>
      );

    });

}

// function useHookWithRefCallback() {
//   // useEffect(() => {

//   //   let timeout: NodeJS.Timeout;
//   //   if (scrollIntoView && ref2.current !== null) {

//   //     timeout = setTimeout(() => { console.log("scrolling"); ref2.current?.scrollIntoView({ behavior: "smooth", block: "start" }) }, 2000);

//   //   }
//   //   return () => { if (timeout !== undefined) { clearTimeout(timeout); }}

//   // }, [ref2]);


//   const ref = React.useRef(null)
//   const setRef = useCallback(node => {
//     if (ref.current) {
//       // Make sure to cleanup any events/references added to the last instance
//     }
    
//     if (node) {
//       // Check if a node is actually passed. Otherwise node would be null.
//       // You can now do what you need to, addEventListeners, measure, etc.
//     }
    
//     // Save a reference to the node
//     ref.current = node
//   }, [])
  
//   return [setRef]
// }

export default ChatItem;
