import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import styles from './ChatBar.module.css';

interface IProps {

  nickname: string,
  addMessage: Function,
  chatName: string,
  setTyping: Function,
  openUploadWindow: Function,

}

const timerCount1: number = 1;
const timerCount2: number = 2;

const ChatBar = (props: IProps) => {

  const [message, setMessage] = useState<string>("");

  const taRef = useRef<HTMLTextAreaElement>(null);
  const [taHeight, setTAHeight] = useState<number>(26);
  const taVPadding = 8;

  const [typingTimer, setTypingTimer] = useState<number>(0);
  const [timerCount, setTimerCount] = useState<number>(timerCount1);

  // const [addingLink, setAddingLink] = useState<boolean>(false);
  // const [linkLink, setLinkLink] = useState<string>("");
  // const [linkText, setLinkText] = useState<string>("");

  let addMessage: Function = props.addMessage;
  const placeholder: string = `Message ${props.chatName}`;

  // Sends the message
  function send() {

    const m: string = message;
    // Don't want to send an empty message
    if (m.trim().length <= 1) { return; }

    addMessage(m);
    // Resets to an empty state
    setMessage("");
    setTAHeight(26);
    setTypingTimer(0);
    setTimeout(() => props.setTyping(false, false), 100);

    // Focus the input box
    taRef.current?.focus();

  }

  // const addLink = React.useCallback(() => {

  //   setMessage(msg => msg + `<a href="${linkLink}">${linkText}</a>`);
  //   setAddingLink(false);
  //   setLinkLink("");
  //   setLinkText("");

  // }, [linkLink, linkText]);

  // Checks and adjusts the TextArea height as needed
  function checkTAHeight(scrollHeight: number) {

    if (scrollHeight !== taHeight) { setTAHeight(scrollHeight); }

  }

  // Tries to shrink the TextArea height if possible
  function tryShrinkTAHeight(target: HTMLTextAreaElement) {
    
    const ta = target.cloneNode(true);
    if (ta instanceof HTMLTextAreaElement) {

      ta.style.position = "fixed";
      ta.style.height = "0px";
      ta.style.width = `${target.clientWidth}px`;
      ta.style.visibility = "hidden";
      const m = target.innerHTML.substring(0, target.innerHTML.length - 1);
      ta.textContent = m;
      ta.value = m;
      document.body.append(ta);
      setTAHeight(ta.scrollHeight);
      ta.remove();

    }
  
  }

  // Handles key press events including:
  // - Sending a message
  // - Showing the user is typing
  function handleKeyboardEvents(e: React.KeyboardEvent<HTMLTextAreaElement>) {

    switch (e.key) {

      case "Backspace":

        // Empty message, do nothing
        if (message.trim().length === 0) {
          console.log("Stopping");
          props.setTyping(false, false);
          break;
        }

        if (e.target instanceof HTMLTextAreaElement) { tryShrinkTAHeight(e.target); }
        // No more text will remain in the field, tell others you have stopped typing
        // if (serialize(message).length === 1) { props.setTyping(false, false); }
        break;

      case "Control":
      case "Alt":

        break;

      // Enter key is pressed
      case "Enter":

        // If Send Button is visible, we are on a mobile device
        // Enter -> Add new line
        if (window.screen.width < 600) {

          // addNewLine();
          break;

        } else {

          // Shift key is also pressed -> Enter a new line
          if (e.shiftKey) { // addNewLine();

          // Shift key is not also pressed -> Send the message
          } else { e.preventDefault(); send(); }
          break;

        }

      // Tell other users you are typing
      default:

        // Typing message not showing, show it
        if (typingTimer === 0) {

          props.setTyping(true, false);
          
        } else {

          // Change the time count to set so useEffect will actually run
          // For some reason it doesn't run when the same number is used every time
          if (timerCount === timerCount1) { setTimerCount(timerCount2); }
          else if (timerCount === timerCount2) { setTimerCount(timerCount1); }

        }
        // Turn on the timer
        setTypingTimer(timerCount);
        break;

    }

  }

  // Manages the timer used to show that the user is typing
  useEffect(() => {
    
    // Timer ran out, tell others you stopped typing
    if (typingTimer === 0) {
      
      // First parameter tells typing has stopped
      // Second parameter tells whether or not there is text in the field
      // props.setTyping(false, serialize(message).length > 0);
      props.setTyping(false, message.length > 0);

    // Timer is still running
    } else if (typingTimer > 0) {

      // Number to manipulate so this hook is not called over and over
      let timer = typingTimer;
      const interval = setInterval(() => {

        // Decrement the timer if possible
        if (timer > 0) { timer -= 1;}
        // Timer ran out, set it to 0
        else { setTypingTimer(timer); }

      }, 1000);
      // Clean up the interval
      return () => { clearInterval(interval); }

    }
  
  }, [typingTimer]);

  useLayoutEffect(() => {

    if (taRef.current !== null) {

      const h = taRef.current.scrollHeight;
      if (h !== taHeight) { setTAHeight(h); }

    }

  }, [message]);

  return (
    <div className={styles.ChatBar}>

      {/* Add Image Button */}
      <button className={styles.AddDropUp} onClick={(e) => { e.preventDefault(); props.openUploadWindow(); }}>+</button>
      {/* <div className={styles.AddDropUp} tabIndex={-1}>
        <div className={styles.AddButton}>+</div>
        <div className={`${styles.AddContent}`}>
          <div className={`${styles.DropItem}`} onClick={(e) => { e.preventDefault(); setAddingLink(true); }}>
            <div>Add Link</div>
          </div>
          <div className={`${styles.DropItem}`} onClick={(e) => { e.preventDefault(); props.openUploadWindow(); }}>
            <div>Add Image</div>
          </div>
        </div>
      </div> */}

      {/* Add Link */}
      {/* { addingLink &&
        <div className={styles.AddLinkWindow}>
          Add Link
          <input type="text" value={linkLink} onChange={e => { setLinkLink(e.target.value); }} placeholder="Link" autoFocus></input>
          <input type="text" value={linkText} onChange={e => { setLinkText(e.target.value); }} placeholder="Display Text"></input>
          <button className={styles.closeAddLink} onClick={(_) => { setAddingLink(false); }}>X</button>
          <button onClick={(e) => { e.preventDefault(); addLink(); }}>Add</button>
        </div>
      } */}

      {/* Input */}
      <div className={styles.InputContainer}>
        <textarea ref={taRef}
                  value={message}
                  onChange={e => { setMessage(e.target.value); checkTAHeight(e.target.scrollHeight); }}
                  className={styles.InputTA}
                  onKeyDown={handleKeyboardEvents}
                  placeholder={placeholder}
                  autoFocus
                  autoCapitalize="true"
                  style={{ padding: `${taVPadding / 2}px 4px`, height: `${taHeight - taVPadding}px`}}>
        </textarea>
      </div>

      {/* Send Button */}
      <button className={styles.SendButton}
              onClick={(e) => { e.preventDefault(); send(); }}>
        Send
      </button>

    </div>
  );

}

export default ChatBar;
