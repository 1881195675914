import './App.css';
import React from 'react';
import io from "socket.io-client";
import Chat from './Components/ChatComponents/Chat/Chat';
import ColorPallete from './Components/UtilComponents/ColorPallete/ColorPallete';
import MyLogin from './Components/UtilComponents/Login/Login';
import ImagesViewer from './Components/ChatComponents/ImagesViewer/ImagesViewer';
import { User, Image, ChatInfo } from '../src/Utils/ChatTypes'

const showColors = false;


// Local server
// const server = "http://192.168.68.82:4000"
// Hosted server
const server = "https://api.smakapp.one";

function App() {

  // Set up socket
  const socketRef = React.useRef<any>(null);

  const [user, setUser] = React.useState<string>();

  const [hasSocket, setHasSocket] = React.useState<boolean>(false);

  const [ chatInfo, setChatInfo ] = React.useState<ChatInfo>();

  const [galleryOpen, setGalleryOpen] = React.useState<boolean>(false);
  const [galleryEndpoint, setGalleryEndpoint] = React.useState<string>("");
  const [galleryImages, setGalleryImages] = React.useState<Array<Image>>([]);
  const [galleryIndex, setGalleryIndex] = React.useState<number>(0);
  const [galleryTags, setGalleryTags] = React.useState<Array<string>>([]);

  function loginAsUser(user: string, deleted: boolean = false) {

    // Fetch IP
    fetch(`${server}/login/${user}`)
    if (!deleted) {
      setUser(user);
      socketRef.current?.emit("getUser", user);
    }

  }
  
  const logoff = React.useCallback(() => {

    if (chatInfo !== undefined && user !== undefined) {
      // Fetch IP
      fetch(`${server}/logoff/${user}`)
    }
  }, [user, chatInfo]);
  
  const openGallery = (endpoint: string, links: Array<Image>, index: number, tags: Array<string>) => {
    
    return;
    setGalleryEndpoint(endpoint);
    setGalleryImages(links);
    setGalleryIndex(index);
    setGalleryTags(tags);
    setGalleryOpen(true);
  
  }
  const closeGallery = () => { setGalleryOpen(false); }

  React.useEffect(() => {

    // Connect to local server
    socketRef.current = io(server);

    // Set has socket
    setHasSocket(true);

    // Connected to the server
    socketRef.current.on("connection", () => { console.log("I'm connected to the server."); });

    // User retrieved from the server
    socketRef.current.on("gotUser", (chatInfo: ChatInfo) => { setChatInfo(chatInfo); });

    return () => { socketRef.current.off("connection"); socketRef.current.off("gotUser"); }

  }, []);

  React.useEffect(() => {

    window.addEventListener("unload", () => logoff())
  })

  return (

    <div className="App">
      { (hasSocket) &&
      <div id="Main" className="Main">

        { showColors

          ? Pallete()
          : !chatInfo

            ? <MyLogin socket={socketRef.current} loginAsUser={loginAsUser} />
            : <Chat socket={socketRef.current} server={server} chatInfo={chatInfo} openGallery={openGallery} />
        
        }
        
        {/* Image Gallery */}
        { galleryOpen &&
          <div id="Gallery">
            <ImagesViewer server={server}
                          socket={socketRef.current}
                          chatId={chatInfo ? chatInfo.chatId : ""}
                          endpoint={galleryEndpoint}
                          users={chatInfo ? chatInfo.members : []}
                          images={galleryImages ? galleryImages : []}
                          imageIndex={galleryIndex}
                          imageTags={galleryTags}
                          closeGallery={closeGallery} />
          </div>
        }
        
      </div> }

    </div>
    
  );
}

let Pallete = () => { return (<ColorPallete />); }

export default App;
