import React from 'react';
import styles from './NicknameWindow.module.css';

interface IProps {

  oldName: string | undefined,
  changeNickname: Function,
  closeWindow: Function,

}
const NicknameWindow = (props: IProps) => {

  const [newName, setNewName] = React.useState<string>(props.oldName ? props.oldName : "");

  const changeNickname = () => {
    


    // Don't accept empty
    if (newName === undefined || newName === "") { return; }
    
    // Capitalize each of the first letters
    props.changeNickname(newName.trim().split(" ").map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(" "));
    closeWindow();
  
  }

  const handleClick = (e: React.MouseEvent) => { e.preventDefault(); changeNickname(); }

  const handleKeyPress = (e: React.KeyboardEvent) => {

      if (e.key === "Enter") { changeNickname(); }

    }

  const closeWindow = () => { props.closeWindow(); }
  
  return (
    <div className={styles.NicknameWindow}>
      <h2><span>Enter a new nickname for</span><br />{props.oldName}</h2>
      <div className={styles.InputContainer}>
        <input className={styles.Input}
               type="text" value={newName}
               onChange={(e) => setNewName(e.target.value)}
               placeholder='New Nickname'
               onKeyPress={handleKeyPress}
               autoFocus >
        </input>
      </div>
      <button className={styles.Submit} onClick={handleClick}>Submit</button>
      <button className={styles.CloseNickname} onClick={closeWindow}>X</button>
    </div>
  )
};

export default NicknameWindow;
