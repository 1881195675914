import React, { FC, useState } from 'react';
import { FaPlus, FaRegArrowAltCircleRight } from "react-icons/fa";
import styles from './UploadPhotoWindow.module.css';

interface UploadPhotoWindowProps {
  server: string,
  chatId: string,
  sender: string,
  imageTags: Array<string>,
  addImage: Function,
  addTags: Function,
  closeWindow: Function,
}


const UploadPhotoWindow: FC<UploadPhotoWindowProps> = (props) => {

  const [file, setFile] = useState<File>();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const tags = (props.imageTags && props.imageTags.length > 0) ? props.imageTags : [];
  const [usedTags, setUsedTags] = useState<Array<string>>([]);
  const [newTags, setNewTags] = useState<Array<string>>([]);
  const [newTagText, setNewTagText] = useState<string>("");
  
  const fileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {

    setFile( e.target.files ? e.target.files[0] : undefined);

  }

  const uploadImage = React.useCallback(async () => {

    setSubmitting(true);
    const fd = new FormData();
    if (file) {

      fd.append("file", file, `${props.chatId}.${file.name.split(".").pop()}`);
      fd.append("sender", props.sender);
      fd.append("chatId", props.chatId);
      fd.append("tags", JSON.stringify(usedTags));
      fd.append("newTags", JSON.stringify(newTags));
      props.addTags(newTags);

    }
    else { alert("Invalid Image File!"); return; }
    // Upload image
    const response = await fetch(`${props.server}/upload`, {

      method: "POST",
      body: fd,

    });
    // Parse response
    const data = await response.json();
    // Close upload window
    props.closeWindow();
    // Send an image message
    props.addImage(data.filename);

  }, [file, newTags, usedTags]);

  const addTag = React.useCallback((t: string = "") => {

    // Tag from array was used
    if (t !== "") { setUsedTags([...usedTags, t.toLowerCase()]); return; }

    // Empty text
    if (newTagText.trim() === "") { return; }

    // Tag is already added
    if (usedTags.includes(newTagText.toLowerCase())) {

      setNewTagText("");
      return;

    }

    setUsedTags([...usedTags, newTagText.toLowerCase()]);
    // Tag is new
    if (!tags.includes(newTagText.toLowerCase())) {

      setNewTags([...newTags, newTagText.toLowerCase()]);

    }
    setNewTagText("");

  }, [newTagText, newTags, usedTags]);

  const removeTag = React.useCallback((index, tag) => {

    setUsedTags(usedTags.filter((_, i) => i !== index));
    setNewTags(newTags.filter((t) => t !== tag));

  }, [usedTags]);
  
  return (
    <div className={styles.UploadPhotoWindow}>
      <button onClick={e => { e.preventDefault(); props.closeWindow(); }} className={styles.CloseSearch}>X</button>
      <input className={styles.Chooser} type="file" name="file" id="file" onChange={fileHandler} accept="image/png, image/jpeg, image/jpg" />
      <div className={styles.ImageContainer}>
        { file && <img src={URL.createObjectURL(file)} className={styles.Image} alt="The one you chose to upload" /> }
        { file && 
          <div className={styles.AddedTags}>
            {
              usedTags.map((t, i) => (

                <span key={i} className={styles.AddedTag}>
                  <span>{t}</span>
                  <span className={styles.RemoveTag} onClick={() => { removeTag(i, t)}}><FaPlus /></span>
                </span>

              ))
            }
          </div>
        }
        { file && 
          <div className={styles.Tags}>
            Add Image Tags: { tags.filter(t => !usedTags.includes(t)).map((t, i) => (
              <span key={i} className={styles.Tag} onClick={() => { addTag(t); }}>{t}</span>
            )) }
            <input type="text"
                   className={styles.TagsTB}
                   value={newTagText}
                   onChange={(e) => setNewTagText(e.target.value)}
                   onKeyDown={(e) => { if (e.key === "Enter") { addTag(); } }}
                   placeholder="New tag..."></input>
          </div>
        }
      </div>
      { file &&
        <div className={styles.Buttons}>
            { !submitting
            ? <button className={styles.Upload} onClick={e => { e.preventDefault(); if (!submitting) { uploadImage(); } }}>
                <span><FaRegArrowAltCircleRight className={styles.Icon} /></span>
              </button>
            : <span className={styles.Loading}><span>💜</span><span>💜</span></span>
            }
        </div>
      }
    </div>
  );

}

export default UploadPhotoWindow;
