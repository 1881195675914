import React from 'react';
import { useTransition, animated } from "react-spring";
import styles from './Login.module.css';

interface IProps {
  socket: any,
  loginAsUser: Function,
}

const Login = (props: IProps) => {
  
  const socket = props.socket;

  const [isUserLogging, setIsUserLogging] = React.useState<boolean>(false);
  const [pw, setPw] = React.useState<string>("");

  const [isUserLoggedIn, setIsUserLoggedIn] = React.useState<boolean>(false);
  const [isDeletedShowing, setIsDeletedShowing] = React.useState<boolean>(false);
  const [isGuestLoggedIn, setIsGuestLoggedIn] = React.useState<boolean>(false);
  const [users, setUsers] = React.useState<string[]>([]);
  const [guests] = React.useState<string[]>([ "Guest 1", "Guest 2", "Guest 3", "Guest 4" ]);

  const pwInputRef = React.useRef<HTMLInputElement>(null);

  function chooseUser(user: string) { props.loginAsUser(user); }

  const submit = () => {
    if (pw === "guest") {
      setIsGuestLoggedIn(true)
    } else if (pw === "jamd") {
      setIsDeletedShowing(true)
      setIsUserLoggedIn(true)
      props.loginAsUser("Deleted", true)
    } else {
      socket?.emit("uLogin", pw);
    }
  }

  const loginPromptAnimation = useTransition(isUserLogging, {

    from: { opacity: 0 },
    enter: { opacity: 1 },

  });

  const userSelectScreenAnimation = useTransition(isUserLoggedIn, {

    from: { opacity: 0 },
    enter: { opacity: 1 },

  });

  const guestSelectScreenAnimation = useTransition(isGuestLoggedIn, {

    from: { opacity: 0 },
    enter: { opacity: 1 },

  });

  React.useEffect(() => {

    socket?.on("uLogged", (users: string[]) => {

      setIsUserLogging(false);
      setPw("");
      setIsUserLoggedIn(true);
      setUsers(users);
    
    });
    socket?.on("uLoggedFailed", () => { console.log("Failed to login :("); })

    return () => { socket?.off("uLogged"); socket?.off("uLoggedFailed"); }

  }, []);

  return (
    <div className={`${styles.Login}`}>

      {/* Log In Screen */}
      { (!isUserLoggedIn && !isGuestLoggedIn) &&
      <div className={styles.Container}>
        <h2>Welcome to SmakApp {":)"}</h2>
        <div className={styles.Options}>
          <h3 className={styles.Option} onClick={() => setIsUserLogging(true)}>Login</h3>
          {/* <h3 className={styles.Option} onClick={() => setIsGuestLoggedIn(true)}>Login as a Guest</h3> */}

          {/* User log in */}
          { loginPromptAnimation((style, logging) =>

            logging

            ? <animated.div className={styles.ULogin} style={style}>
              <div>Please Enter Your Password</div>
              <div className={styles.PWInput} ref={pwInputRef}>
                <input type="password"
                      value={pw}
                      onChange={event => setPw(event.target.value)}
                      onKeyDown={event => { if (event.key === "Enter") { submit(); }}}
                      autoFocus></input>
              </div>
              <button className={styles.Close} onClick={() => { setIsUserLogging(false); setPw(""); }}>X</button>
              <button className={styles.Submit} onClick={submit}>Submit</button>
            </animated.div>

            : ""

          )}

        </div>
      </div> }

      {/* User Selection Screen */}
      { userSelectScreenAnimation((style, logged) =>

        (logged && isDeletedShowing)

        ? <animated.div style={style} className={styles.Container} >
          <h2 style={{ color: "red" }}>Deleted</h2>
        </animated.div>

        : logged

        ? <animated.div style={style} className={styles.Container} >
          <h2>Please Select A User</h2>
          <div className={styles.Options}>
            {users.map((user, i) => { return <h3 key={i} className={styles.Option} onClick={() => chooseUser(user)}>{user}</h3> })}
          </div>
        </animated.div>

        : ""

      )}

      {/* Guest Selection Screen */}
      { guestSelectScreenAnimation((style, logged) =>

        (logged)

        ? <animated.div style={style} className={styles.Container} >
          <h2>Please Select A Guest</h2>
          <div className={styles.Options}>
            {guests.map((guest, i) => { return <h3 key={i} className={styles.Option} onClick={() => chooseUser(guest)}>{guest}</h3> })}
          </div>
        </animated.div>

        : ""

      )}

    </div>
  )
};

export default Login;
