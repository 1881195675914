import { MouseEvent, useEffect, useState, useCallback } from "react";
import { FaSearch, FaPhone, FaEllipsisV } from "react-icons/fa";
import styles from './ChatHeader.module.css';
import { User } from '../../../Utils/ChatTypes'
import moment from "moment";
import emitter from "../../../Utils/emitter";

interface IProps {

  socket: any,
  userId: string,
  chatId: string,
  chatName: string,
  members: Array<User>,
  otherUserOnline: boolean,
  openSearchWindow: Function,
  openChangeNickname: Function,
  onlineMessage: string,
  offlineMessage: string,

}

const ChatHeader = (props: IProps) => {

  const socket = props.socket;
  
  const openSearchWindow = (e: MouseEvent) => { e.preventDefault(); props.openSearchWindow(); }
  const openChangeNickname = (e: MouseEvent, userId: string) => { e.preventDefault(); props.openChangeNickname(userId); }

  const callURL = `https://api.smakapp.one/videoRoom/${props.chatId}`;
  const [numInCall, setNumInCall] = useState<number>(0);

  const [otherUser, setOtherUser] = useState<User | null>(null);

  const [lastOnline, setLastOnline] = useState<string>();

  // Removes all other socket connections for this user
  const purgeConnections = useCallback(() => {

    socket.emit("purgeConnections", emitter({ userId: props.userId, socketId: socket.id }));

  }, []);

  const getOtherUser = useCallback(() => {

    if (props.members.length === 2) {
      return props.members[0].userId === props.userId ? props.members[1] : props.members[0];
    } else {
      return null;
    }

  }, []);

  useEffect(() => {

    socket.on("userConnected", () => { setNumInCall(num => num + 1); });
    socket.on("userDisconnected", () => { setNumInCall(num => num - 1); });

    return () => {

      socket.off("userConnected");
      socket.off("userDisconnected");

    }

  }, [props.chatId, socket]);

  // Updates last online time every minute
  useEffect(() => {

    if (props.otherUserOnline || otherUser === null) { return; }

    let newLastSeen = moment(otherUser?.lastSeen).fromNow();
    newLastSeen = newLastSeen.charAt(0).toUpperCase() + newLastSeen.slice(1);
    setLastOnline(newLastSeen);

    const interval = setInterval(() => {
      newLastSeen = moment(otherUser?.lastSeen).fromNow();
      newLastSeen = newLastSeen.charAt(0).toUpperCase() + newLastSeen.slice(1);
      setLastOnline(newLastSeen);
    }, 60000)

    return () => { clearInterval(interval); }

  }, [props.otherUserOnline, otherUser])

  useEffect(() => {

    setOtherUser(getOtherUser());

  }, []);

  useEffect(() => { }, [props.members])
  
  return (
    <div className={styles.ChatHeader}>

      {/* Header Text */}
      <div className={styles.HeaderText}>
        {props.chatName}
        {/* If there is only one other user, show online status and last online */}
        { (false && props.members.length === 2) &&
        <div className={styles.StatusText}>
          {/* Online status */}
          { props.otherUserOnline
          ? <span className={styles.Online}>{props.onlineMessage}</span>
          : <span className={styles.Offline}>{props.offlineMessage}</span> }

          {/* Last online */}
          { !props.otherUserOnline &&
            <span className={styles.Offline}>Last Seen: {lastOnline}</span>
          }
        </div> }
      </div>

      <div className={styles.IconDropdown}>
        {/* Ellipses Icon */}
        <button className={styles.IconDropBtn}><FaEllipsisV /></button>
        <div className={styles.IconDropContent}>
          {/* Members list */}
          <div className={`${styles.MembersDropdown}`}>
            <button className={`${styles.IconDropDownItem}`}>View Members</button>
            <div className={`${styles.MembersDropContent}`}>
              { props.members.map((member) => {

                return (
                  <div key={member.nickname} className={`${styles.MembersDropItem}`}>
                    <div className={`${styles.MemberName}`}>{member.nickname}</div> <button className={`${styles.ChangeNicknameBtn}`} onClick={(e) => openChangeNickname(e, member.userId)}>Change Nickname</button>
                  </div>
                );

              }) }
            </div>
          </div>
          {/* Call Icon */}
          { numInCall > 0
            // Call is active
            ? <button style={{ borderColor: "limegreen", color: "limegreen" }} onClick={() => { window.open(callURL, "_blank"); }}>Join Call</button>
            // Call is not active
            : <button className={styles.IconDropDownItem} onClick={() => { window.open(callURL, "_blank"); }}><FaPhone style={{ fontSize: "12px", marginRight: "10px" }} /> Start Call</button>
          }
          <button onClick={purgeConnections} className={styles.IconDropDownItem}>Log Out Of All Other Devices</button>
        </div>
      </div>

      {/* Icons */}
      <div className={styles.Icons}>
        {/* Search Icon */}
        <button onClick={openSearchWindow}><FaSearch /></button>
      </div>

    </div>
  )
};

export default ChatHeader;
