import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FaChevronLeft, FaChevronRight, FaPlus } from 'react-icons/fa';
import emitter from '../../../Utils/emitter';
import styles from './ImagesViewer.module.css';

type User = {
  userId: string,
  name: string,
  nickname: string,
  isOnline: boolean,
}
type Image = {
  id: string,
  gfsId: string,
  filename: string,
  sender: string,
  date: Date,
  tags: Array<string>
}

interface ImagesViewerProps {

  server: string,
  socket: any,
  chatId: string,
  endpoint: string,
  users: Array<User>,
  images: Array<Image>,
  imageIndex: number,
  imageTags: Array<string>,
  closeGallery: Function,

}

const ImagesViewer: FC<ImagesViewerProps> = (props: ImagesViewerProps) => {
  
  // Reference to the div containing all the overlay elements
  const overlays = useRef<Element[]>([]);
  
  // Image objects
  const [images, setImages] = useState<Array<Image>>([]);

  // Index of the currently displaying Image
  const [displayImgIdx, setDisplayImgIdx] = useState<number>(0);

  // Starts/Resets the overlay timer
  const defaultTimer = { data: 2 };
  const emptyTimer = { data: 0 };
  const [showTimer, setShowTimer] = useState<{ data: number }>(defaultTimer);

  // Reference to the slider
  const sliderRef = useRef<HTMLDivElement>(null);
  // Flag for whether or not the mouse is over an overlay element
  const [mouseOnOverlay, setMouseOnOverlay] = useState<boolean>(false);
  // Current slider X position
  const [sliderPosition, setSliderPosition] = useState<number>(0);
  // Target slider X position
  const [targetPosition, setTargetPosition] = useState<number>(0);
  // Flag for whether or not the slider is being touched
  const [touching, setTouching] = useState<boolean>(false);

  // List of any previously used tags in this chat
  const [tags, setTags] = useState<Array<string>>(props.imageTags);
  // Tags being used to describe this image
  const [usedTags, setUsedTags] = useState<Array<string>>([]);
  // Tags not used to describe this image
  const [newTags, setNewTags] = useState<Array<string>>([]);
  // New tag input text reference
  const [newTagText, setNewTagText] = useState<string>("");
  // Flag for if the "Save Changes" button should be showing
  const [saveTagsBtnVisible, setSaveTagsBtnVisible] = React.useState<boolean>(false);

  // Gets images from the endpoint
  const getImageLinks = async () => {

    // Get image names
    const response = await (await fetch(props.endpoint)).json();
    // No images left to retrieve
    // if (response.length === 0) {
      
    //   if (imagePage === 1) { setImages([]); }
    //   setMoreImages(false);
    //   setLoadingImages(false);
    //   return;
    
    // }
    // No images left to retrieve after the current batch
    // if (response.length < 6) { setMoreImages(false); }

    // First page, remove any older previous images
    // if (imagePage === 1) { setImages([...response]); }
    // Otherwise, append to the end of the current list
    // else { setImages([...images, ...response]); }
    // setImagePage(i => i + 1);

    // setLoadingImages(false);

    setImages([...response]);

  }

  // Adds a tag to the usedTags list
  const addTag = React.useCallback((t: string = "") => {

    // Tag from array was used
    if (t !== "") { setUsedTags([...usedTags, t.toLowerCase()]); return; }

    // Empty text
    if (newTagText.trim() === "") { return; }

    // Tag is already added
    if (usedTags.includes(newTagText.toLowerCase())) {

      setNewTagText("");
      return;

    }

    setUsedTags([...usedTags, newTagText.toLowerCase()]);
    // Tag is new
    if (!tags.includes(newTagText.toLowerCase())) {

      setNewTags([...newTags, newTagText.toLowerCase()]);

    }
    setNewTagText("");

  }, [newTagText, newTags, tags, usedTags]);

  // Removes a tag from the used tags list
  const removeTag = React.useCallback((index, tag) => {

    setUsedTags(usedTags.filter((_, i) => i !== index));
    setNewTags(newTags.filter((t) => t !== tag));

  }, [newTags, usedTags]);

  // Updates tags and notifies other users if necessary
  const updateTags = React.useCallback(() => {

    // If there are new tags, others need to be notified
    if (newTags.length > 0) {

      props.socket.emit("updateChatTags", emitter({
        
        chatId: props.chatId,
        imageId: images[displayImgIdx].id,
        tags: usedTags,
        newTags
      
      }));


    } else {

      props.socket.emit("updateImageTags", emitter({

        chatId: props.chatId,
        imageId: images[displayImgIdx].id,
        tags: usedTags,

      }));

    }
    // Modify state
    setTags([...tags, ...newTags]);

    let newImages = [...images];
    let newImage = {...newImages[displayImgIdx]};
    newImage.tags = usedTags;
    newImages[displayImgIdx] = newImage;
    setImages(newImages);

    setNewTags([]);

  }, [displayImgIdx, images, newTags, props.chatId, props.socket, tags, usedTags]);

  // Hides the overlays
  const hideOverlays = () => {

    overlays.current.forEach((e) => { e.classList.add(styles.OverlaysHiding) });

  }

  // Shows the overlays
  const showOverlays = () => {

    overlays.current.forEach((e) => { e.classList.remove(styles.OverlaysHiding)});

  }
  
  // Closes this component
  const closeGallery = () => { props.closeGallery(); }

  // Checks if the two given arrays are equal
  function areArraysEqual(a: Array<any>, b: Array<any>) {

    return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val) => b.includes(val));

  }

  // Adjusts the index of the visible image if the slider is scrolled
  const getVisibleIndex = React.useCallback((e: any) => {

    // Make sure the slider is valid
    if (sliderRef.current != null) {

      // Get the current position of the slider
      const newPos = e.target.scrollLeft;

      // Variable sliderPosition represents the sliders previous position
      // Variable targetPosition represents where the slider is going
      // If newPos is between the two variables, it is currently moving to it's new position
      // If newPos is less than both, a new target needs to be assigned -> Previous Image
      if (newPos < sliderPosition && newPos < targetPosition) {

        // Decrement the display index
        setDisplayImgIdx(i => i - 1 < 0 ? 0 : i - 1);
        // Reset the overlay timer
        setShowTimer(defaultTimer);

      // If newPos is greater than both, a new target needs to be assigned -> Next Image
      } else if (newPos > sliderPosition && newPos > targetPosition) {

        // Increment the display index
        setDisplayImgIdx(i => (i + 1) > (images.length - 1) ? i : i + 1);
        // Reset the overlay timer
        setShowTimer(defaultTimer);

      // Otherwise, update the previous slider position to the current position
      } else { setSliderPosition(sliderRef.current.scrollLeft); }

    }

  }, [images, sliderPosition, targetPosition]);

  // Toggles the overlay
  const toggleOverlay = React.useCallback(() => {

    showTimer.data === 0 ? setShowTimer(defaultTimer) : setShowTimer(emptyTimer);

  }, [showTimer])

  // Whenever the display image index is modified...
  useEffect(() => {

    // Make sure the slider is valid
    if (sliderRef.current != null) {

      // Get the image which wants to be shown
      const currentImg: any = sliderRef.current.children[displayImgIdx];
      // If the image is valid and the current position of the slider is not already at that image...
      if (currentImg && sliderRef.current.scrollLeft !== currentImg.offsetLeft) {

        // Scroll the target image into view
        currentImg.scrollIntoView();
        // Set the target image's position
        setTargetPosition(currentImg.offsetLeft);

      }

    }

  }, [displayImgIdx])

  // Whenever the usedTags list is modified...
  useEffect(() => {

    // If there are images and the usedTags array is equal to the current image's tags array...
    if (images && images.length > 0 && areArraysEqual(usedTags, images[displayImgIdx].tags)) {

      setSaveTagsBtnVisible(false);

    } else { setSaveTagsBtnVisible(true); }

  }, [displayImgIdx, images, usedTags]);

  // Whenever the display image changes, update the usedTags list
  useEffect(() => {

    if (images.length > 0 && displayImgIdx >= 0 && displayImgIdx < images.length) {

      setUsedTags(images[displayImgIdx].tags);

    }

  }, [displayImgIdx, images]);

  // Manages showing/hiding the overlays with the timer
  useEffect(() => {

    // Don't run timer if mouse is over an overlay element
    if (mouseOnOverlay) { return; }

    // Timer ended, hide overlays
    if (showTimer.data === 0) { hideOverlays(); }
    // Timer still going...
    else if (showTimer.data > 0) {
      
      showOverlays();
      let timer = showTimer.data;
      // Every second...
      const interval = setInterval(() => {

        // If the mouse is not over an overlay element...
        if (!mouseOnOverlay) {

          // If the timer is greater than 0, subtract one
          if (timer > 0) { timer -= 1; }
          // Otherwise notify that the timer has ended
          else { setShowTimer(emptyTimer); }

        }

      }, 1000);

      // Clear the interval whenever this Effect is modified
      return () => { clearInterval(interval); }

    }

  }, [showTimer, mouseOnOverlay]);

  // On create
  useEffect(() => {

    // Get all the overlay elements
    overlays.current = Array.from(document.getElementsByClassName("overlay-element"));
    overlays.current.forEach((e) => {

      // Set MouseEnter and MouseLeave event listeners for all overlay elements
      e.addEventListener("mouseenter", () => { setMouseOnOverlay(true); })
      e.addEventListener("mouseleave", () => { setMouseOnOverlay(false); })

    });
    // Get images if needed or set them if provided
    props.images.length === 0 ? getImageLinks() : setImages(props.images);
    // Set starting image index
    setDisplayImgIdx(props.imageIndex);

  }, []);
  
  return (

    <div className={styles.ImagesViewer}
         onMouseMove={() => { if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) { setShowTimer(defaultTimer);} }}
         onClick={() => { toggleOverlay(); }}>

      {/* Overlays */}
      <div id="ivOverlays" className={styles.Overlays}>

        {/* Header */}
        <div className={`${styles.IV_Header} overlay-element`}>
          <div className={styles.Container}>
            <div className={styles.CloseIV} onClick={closeGallery}>X</div>
          </div>
        </div>

        {/* Left Arrow */}
        <button className={`${styles.IV_Left} overlay-element`}
                onClick={() => { setDisplayImgIdx(idx => idx - 1); }}
                disabled={displayImgIdx === 0}>
          <div className={styles.Container}>
            <FaChevronLeft />
          </div>
        </button>

        {/* Right Arrow */}
        <button className={`${styles.IV_Right} overlay-element`}
                onClick={() => { setDisplayImgIdx(idx => idx + 1); }}
                disabled={displayImgIdx === images.length - 1}>
          <div className={styles.Container}>
            <FaChevronRight />
          </div>
        </button>

        {/* Footer */}
        <div className={`${styles.IV_Footer} overlay-element`}>
          <div className={styles.Container}>
            <div className={styles.AddedTags}>
              { images.length > 0 &&
                usedTags.map((t, i) => (

                  <span key={i} className={styles.AddedTag}>
                    <span>{t}</span>
                    <span className={styles.RemoveTag} onClick={() => { removeTag(i, t)}}><FaPlus /></span>
                  </span>

                ))
              }
            </div>
            <div className={styles.Tags}>
              Add Tags: { tags.filter((t: any) => !usedTags.includes(t)).map((t: any, i: any) => (
                <span key={i} className={styles.Tag} onClick={() => { addTag(t); }}>{t}</span>
              )) }
            </div>
            <input type="text"
                    className={styles.TagsTB}
                    value={newTagText}
                    onChange={(e) => setNewTagText(e.target.value)}
                    onKeyDown={(e) => { if (e.key === "Enter") { addTag(); } }}
                    placeholder="New tag..."></input>
            <button className={styles.SaveTagChangesBtn}
                    onClick={() => { updateTags(); }}
                    style={{ visibility: saveTagsBtnVisible ? "visible" : "collapse" }}>
              Save Changes
            </button>
          </div>
        </div>

      </div>

      {/* Images */}
      { images.length > 0 &&
        <div ref={sliderRef} className={styles.ImageSlider}
             style={{ scrollBehavior: sliderRef.current ? "smooth" : "initial" }}
             onTouchStart={() => setTouching(true)}
             onTouchEnd={() => setTouching(false)}
             onScroll={(e) => { !touching && getVisibleIndex(e) }}
             
             draggable={false}>
          { images.map((img, i) => {

            sliderRef.current && sliderRef.current.focus();
            return <img key={i} src={`${props.server}/image/${img.filename}`} alt="" draggable={false}></img>

          })}
        </div>
      }
    </div>
  );

}

export default ImagesViewer;
