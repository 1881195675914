import { useEffect, useState, useCallback } from 'react';
import ChatBar from '../ChatBar/ChatBar';
import ChatHeader from '../ChatHeader/ChatHeader';
import ChatWindow from '../ChatWindow/ChatWindow';
import NicknameWindow from '../NicknameWindow/NicknameWindow';
import SearchWindow from '../SearchWindow/SearchWindow';
import UploadPhotoWindow from '../UploadPhotoWindow/UploadPhotoWindow';
import styles from './Chat.module.css';
import { User, ChatInfo } from '../../../Utils/ChatTypes'
import emitter from '../../../Utils/emitter';

// const moment = require("moment");

interface IProps {
  socket: any,
  server: string,
  chatInfo: ChatInfo,
  openGallery: Function,
}

const Chat = (props: IProps) => {

  const socket = props.socket;

  //#region States

  // Sample users for the chat app
  const chatInfo: ChatInfo = props.chatInfo;
  const { sender, chatId } = chatInfo;
  const [nickname, setNickname] = useState<string>(chatInfo.nickname);
  const [chatName, setChatName] = useState<string>(chatInfo.chatName);
  const [members, setMembers] = useState<Array<User>>(chatInfo.members);
  const [imageTags, setImageTags] = useState<Array<string>>(chatInfo.imageTags);

  // Search Window Open state
  const [isSearching, setIsSearching] = useState<boolean>(false);

  // Upload Window Open State
  const [isUploading, setIsUploading] = useState<boolean>(false);

  // Other User Id
  const otherUserId = members.find(m => m.userId !== sender)?.userId;
  const [otherUserOnline, setOtherUserOnline] = useState<boolean>(false);

  // Chat Messages
  const [onlineMessage, setOnlineMessage] = useState<string>(props.chatInfo.chatMessages.onlineMessage);
  const [offlineMessage, setOfflineMessage] = useState<string>(props.chatInfo.chatMessages.offlineMessage);
  const [typingEmoji, setTypingEmoji] = useState<string>(props.chatInfo.chatMessages.typingEmoji);

  // Nickname is being changed
  const [isChangingNickname, setIsChangingNickname] = useState<boolean>(false);
  const [nicknameChangeUser, setNicknameChangeUser] = useState<User>();
  const [nicknames, setNicknames] = useState<Array<string>>(members.map((m) => { return m.nickname }));

  //#endregion

  //#region Refs

  // const searchRef = useRef<HTMLInputElement>(null);

  //#endregion

  //#region Callbacks

  //#region Search Callbacks

  const openSearchWindow = useCallback(() => { setIsSearching(true); }, []);

  const closeSearchWindow = useCallback(() => { setIsSearching(false); }, []);

  //#endregion

  //#region Photo Callbacks

  const openUploadWindow = useCallback(() => { setIsUploading(true); }, []);

  //#endregion

  //#region Send Message & Typing Callbacks

  const sendMessage = useCallback((content: string, isImage: boolean = false) => {

    // Send the message to other users
    socket.emit("message", emitter({ chatId, sender, nickname, content, isImage }));
  
  }, [nickname]);

  // Emit to other users whether this user started or stopped typing
  const setTyping = useCallback((isTyping: boolean, hasText: boolean) => {

    socket.emit("typing", emitter({ chatId, isTyping, sender, nickname, hasText }));
  
  }, [nickname]);

  //#endregion

  //#region Image Tags

  const addTags = useCallback((tags: Array<string>) => {

    setImageTags([...imageTags, ...tags.map(t => t.toLowerCase())]);

  }, [imageTags]);

  //#endregion

  //#region Other User Login Status Callbacks

  const getMemberIndex = useCallback((userId: string) => {

    return members.findIndex(m => m.userId === userId);

  }, [members]);

  const setMemberOnline = useCallback((userId: string) => {

    const memberIdx = getMemberIndex(userId);
    if (memberIdx !== -1) { members[memberIdx].isOnline = true; }
    if (otherUserId === userId) { setOtherUserOnline(true); }

  }, [getMemberIndex, members, otherUserId]);

  const setMemberOffline = useCallback((userId: string) => {

    const memberIdx = getMemberIndex(userId);
    if (memberIdx !== -1) { members[memberIdx].isOnline = false; members[memberIdx].lastSeen = new Date(); }
    if (otherUserId === userId) { setOtherUserOnline(false); }

  }, [getMemberIndex, members, otherUserId]);

  //#endregion

  //#region Nickname Callbacks

  const openChangeNickname = useCallback((userId: string) => { setIsChangingNickname(true); setNicknameChangeUser(members.find(m => m.userId === userId)); }, [members]);

  const changeNickname = useCallback((newNickname: string) => {

    const changeId = nicknameChangeUser?.userId;
    socket.emit("changeNickname", emitter({ chatId, changeId, newNickname }));

  }, [nicknameChangeUser]);

  const updateNickname = useCallback((userId: string, nickname: string) => {

    const idx = members.findIndex(m => m.userId === userId);
    setMembers(members.map((m, i) => { if (i === idx) { m.nickname = nickname; } return m; }));
    setNicknames(nicknames.map((n, i) => { if ( i === idx) { return nickname; } else { return n; } }));
    if (userId !== sender) { setChatName(nickname); }
    if (userId === sender) { setNickname(nickname); }

  }, [members, nicknames]);

  //#endregion

  //#endregion

  //#region Effects

  // Tags updated
  useEffect(() => {

    socket.on("chatTagsUpdated", (r: any) => { setImageTags([...r.newTags, ...imageTags]); })
    return () => { socket.off("chatTagsUpdated"); }

  }, [imageTags, socket]);

  // Set up socket functions
  useEffect(() => {

    // Check if other user is logged in
    const idx = members.findIndex((m) => m.userId === otherUserId);
    setOtherUserOnline(members[idx].isOnline);

    // Nickname changed
    socket.on("nicknameChanged", (r: any) => { updateNickname(r.changeId, r.newNickname); });
    // Another user logged on
    socket.on("otherUserLoggedOn", (userId: string) => setMemberOnline(userId));
    // Another user logged off
    socket.on("otherUserLoggedOff", (userId: string) => setMemberOffline(userId));

    return () => {
      
      socket.off("nicknameChanged");
      socket.off("otherUserLoggedOn");
      socket.off("otherUserLoggedOff");
    
    }
    
  }, []);

  //#endregion
  
  // Create the UI
  return (
    <div className={styles.Container}>

      {/* Chat Window */}
      <div className={`${styles.Chat} ${(isSearching || isChangingNickname || isUploading) ? styles.Disabled : ""}`}>
        <ChatHeader socket={socket}
                    userId={sender}
                    chatId={chatId}
                    chatName={chatName}
                    otherUserOnline={otherUserOnline}
                    members={members}
                    openSearchWindow={openSearchWindow}
                    openChangeNickname={openChangeNickname}
                    onlineMessage={onlineMessage}
                    offlineMessage={offlineMessage} />
        <ChatWindow socket={socket}
                    server={props.server}
                    sender={sender}
                    chatId={chatId}
                    isSearching={isSearching}
                    openGallery={props.openGallery}
                    typingEmoji={typingEmoji} />
        <ChatBar nickname={nickname}
                 chatName={chatName} 
                 addMessage={(content: string) => sendMessage(content)}
                 setTyping={setTyping}
                 openUploadWindow={openUploadWindow} />

      </div>

      {/* Searching Window */}
      { isSearching && <SearchWindow server={props.server}
                                     socket={socket}
                                     chatId={chatId}
                                     members={members}
                                     tags={imageTags}
                                     closeSearch={closeSearchWindow}
                                     openGallery={props.openGallery} /> }

      {/* Change Nickname Window */}
      { isChangingNickname && <NicknameWindow oldName={nicknameChangeUser?.nickname}
                                              changeNickname={changeNickname}
                                              closeWindow={() => setIsChangingNickname(false)} />}

      {/* Upload Photo Window */}
      { isUploading && <UploadPhotoWindow server={props.server}
                                          chatId={chatId}
                                          sender={sender}
                                          imageTags={imageTags}
                                          addImage={(filename: string) => sendMessage(filename, true)}
                                          addTags={(tags: Array<string>) => addTags(tags)}
                                          closeWindow={() => setIsUploading(false)} />}

    </div>

  )

};

export default Chat;
