import styles from './ChatWindow.module.css';
import ChatItem from "../ChatItem/ChatItem";
import Message from '../../../Models/Message';
import React, { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import emitter from '../../../Utils/emitter';

const moment = require("moment");

interface IProps {

  socket: any,
  server: string,
  sender: string,
  chatId: string,
  isSearching: boolean,
  openGallery: Function,
  typingEmoji: string,

}

const ChatWindow = (props: IProps) => {

  const socket = props.socket;
  let user: string = props.sender;
  const windowRef = useRef<HTMLDivElement>(null);

  // Message List state
  const [messages, setMessages] = useState<Array<Message>>([]);
  // Number of Messages sent today
  const [messageCount, setMessageCount] = useState<number>(0);
  // Number of new Messages (for when the user is scrolled up or viewing an older message context)
  const[newMsgCount, setNewMsgCount] = useState<number>(0);
  // Message that was selected after searching
  const [selectedMessageId, setSelectedMessageId] = useState<string>("");
  const [selectedMessageIdx, setSelectedMessageIdx] = useState<number>(-1);

  // Another User is Typing state
  const [isTyping, setIsTyping] = useState<boolean>(false);
  // Which user is typing
  const [typingUser, setTypingUser] = useState<string>();
  // User has paused typing
  const [pausedTyping, setPausedTyping] = useState<boolean>(false);

  // Whether or not older messages are currently loading
  const [loadingOlder, setLoadingOlder] = useState<boolean>(false);
  // Whether or not older messages are currently loading
  const [loadingNewer, setLoadingNewer] = useState<boolean>(false);
  // Whether or not there are more older messages to load
  const [moreMsgsOlder, setMoreMsgsOlder] = useState<boolean>(true);
  // Whether or not there are more newer messages to load
  const [moreMsgsNewer, setMoreMsgsNewer] = useState<boolean>(false);
  // Whether or not the user is scrolled up beyond a certain threshold
  const [isScrolledUp, setIsScrolledUp] = useState<boolean>(false);

  // Used in the addMessage() function
  // On iOS, for some reason the scrollTo() function does not work with the same value twice in a row
  // So this flag switches the scrollTo() y value
  const [scrollAlternator, setScrollAlternator] = useState(1);
  
  //#region Add Message Callbacks

  const addMessage = useCallback((m: Message) => {

    // Update Message List and Number of Messages states
    const msgs = messages;
    setMessages([]);
    setMessages([m, ...msgs]);
    setMessageCount(messageCount => messageCount + 1);
    
    // Alternate scroll point to support all browsers
    if (!isScrolledUp && scrollAlternator === 0) {

      windowRef.current?.scrollTo(0, 0);
      setScrollAlternator(1);
      
    } else if (!isScrolledUp) {
      
      windowRef.current?.scrollTo(0, -1);
      setScrollAlternator(0);

    }

  }, [isScrolledUp, messages, scrollAlternator]);

  const addBulkMessage = useCallback((mList: Array<Message>) => {

    // Update Message List and Number of Messages states
    setMessages([]);
    setMessages(mList);

  }, []);

  const addBulkMessageToFront = useCallback((mList: Array<Message>) => {

    // Add this Message to the list
    const newMessages = [ ...mList, ...messages ];

    // Update Message List and Number of Messages states
    setMessages(newMessages);

  }, [messages]);

  const addBulkMessageToBack = useCallback((mList: Array<Message>) => {

    // Add this Message to the list
    const newMessages = [ ...messages, ...mList ];

    // Update Message List and Number of Messages states
    setMessages(newMessages);

  }, [messages]);

  const loadMessagesOlder = useCallback(() => {
    
    // Ensure function is not called multiple times for the same batch
    if (loadingOlder) { return; }
    // No more messages, no point in running
    if (!moreMsgsOlder) { return; }
    
    setLoadingOlder(true);
    const lastMessage = messages[messages.length - 1];
    socket.emit("loadMessagesOlder", emitter({ chatId: props.chatId, date: lastMessage.datetime_utc_moment }));
  
  }, [loadingOlder, moreMsgsOlder, messages, socket, props.chatId]);

  const loadMessagesNewer = useCallback(() => {

    // Ensures function is not called multiple times for the same batch
    if (loadingNewer) { return; }
    // No more messages, no point in running
    if (!moreMsgsNewer) { return; }

    setLoadingNewer(true);
    const latestMessage = messages[0];
    socket.emit("loadMessagesNewer", emitter({ chatId: props.chatId, date: latestMessage.datetime_utc_moment }));
  
  }, [loadingNewer, moreMsgsNewer, messages, socket, props.chatId]);

  //#endregion

  const initMessages = useCallback(() => {

    // Get previous messages on start up
    const today = new Date();
    const todate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
    // Start at 6 AM of the current day
    let todayMoment = moment(todate, "YYYY-MM-DD").utc().add(6, "hours");
    // It's past midnight but not past 6 AM so use the previous day as the start point
    if (todayMoment.diff(moment(new Date())) > 0) {
      todayMoment = todayMoment.subtract(1, "day");
    }
    const todayStart = todayMoment.format();
    // End at 6 AM of the next day
    const todayEnd = todayMoment.add(1, "day").format();
    socket.emit("initMessages", emitter({chatId: props.chatId, todayStart: todayStart, todayEnd: todayEnd }));

  }, []);

  useEffect(() => {

    initMessages();

    // Received previous messages -> add them to the list
    socket.on("initted", (r: any) => { 
  
      // Add all messages
      addBulkMessage(r.messages.map((m: any) => {

        return new Message(m.sender, m.nickname, m.content, m.date, m._id, m.isImage);
    
      }));
      setMessageCount(r.count);
      setMoreMsgsNewer(false);
      setMoreMsgsOlder(true);
      setLoadingNewer(false);
      setLoadingOlder(false);
      setNewMsgCount(0);
  
    });

    // Another user is typing
    socket.on("typing", (response: any) => {
      
      if (response.sender !== props.sender) {
        
        setIsTyping(response.isTyping);
        setTypingUser(response.nickname);
        setPausedTyping(response.hasText);
      
      }

    });

    return () => {

      socket.off("initted");
      socket.off("typing");

    }

  }, []);

  // Setting up sockets that need to update with states
  useEffect(() => {
    
    // Message was added
    socket.on("message", (m: any) => {

      // If the user is viewing an older message context, notify then
      if (moreMsgsNewer) {

        setNewMsgCount(newMsgCount + 1);

      // Otherwise, add the message
      } else {

        if (isScrolledUp) { setNewMsgCount(newMsgCount + 1); }
        addMessage(new Message(m.sender, m.nickname, m.content, m.date, m._id, m.isImage));

      }

    });
    
    socket.on("contextShown", ({ messageId, messages: msgs}: { messageId: string, messages: any}) => {
      
      setSelectedMessageId(messageId);
      for (let i = 0; i < msgs.length; i++) {
        if (msgs[i]._id === messageId) {
          setSelectedMessageIdx(i);
          break;
        }
      }
      // Add all messages, removing the old ones
      addBulkMessage(msgs.map((m: any) => {
        
        return new Message(m.sender, m.nickname, m.content, m.date, m._id, m.isImage);
        
      }));
      setMoreMsgsOlder(true);
      setMoreMsgsNewer(true);
      
    });

    // Batch of older messages was added
    socket.on("messagesLoadedOlder", (msgs: any) => {

      if (msgs.length === 0) { setMoreMsgsOlder(false); setLoadingOlder(false); return; }
      if (msgs.length < 100) { setMoreMsgsOlder(false); }
      // Add all messages to the top fo the list
      addBulkMessageToBack(msgs.map((m: any) => {

        return new Message(m.sender, m.nickname, m.content, m.date, m._id, m.isImage);

      }));
      setLoadingOlder(false);

    });

    // Batch of newer messages was added
    socket.on("messagesLoadedNewer", (msgs: any) => {

      if (msgs.length < 100) {

        setMoreMsgsNewer(false);
        setNewMsgCount(0);
        if (msgs.length === 0) { setLoadingNewer(false); return; }

      }
      // Add all messages to the top fo the list
      addBulkMessageToFront(msgs.reverse().map((m: any) => {

        return new Message(m.sender, m.nickname, m.content, m.date, m._id, m.isImage);

      }));
      setLoadingNewer(false);

    });
    
    // Destroy the listener when this is re-initialized
    return () => {

      socket.off("message");
      socket.off("messagesLoaded");
      socket.off("messagesLoadedOlder");
      socket.off("messagesLoadedNewer");
      socket.off("contextShown");

    }

  }, [addBulkMessage, addBulkMessageToBack, addBulkMessageToFront, addMessage, isScrolledUp, messages, moreMsgsNewer, newMsgCount, socket]);

  return (
    <div className={styles.ChatWindow}>
    
      {/* Messages Window */}
      <div ref={windowRef} className={styles.Messages}
           onScroll={(e: any) => {
             if (e.target.scrollTop < -600 && !isScrolledUp) { setIsScrolledUp(true); } else if (e.target.scrollTop > -600 && isScrolledUp) { setIsScrolledUp(false); }
             moreMsgsOlder && (e.target.scrollHeight + e.target.scrollTop < e.target.clientHeight + 10)
             ? loadMessagesOlder()
             : moreMsgsNewer && (e.target.scrollTop >= 0) && loadMessagesNewer();
           }}>
        { messages.map((m: Message, i: number) => {

            const senderClass = user === m.sender ? "Sender" : "Receiver";
            const previousSender = messages.length > i + 1 ? messages[i + 1].nickname : "";
            const previousDate = messages.length > i + 1 ? messages[i + 1].datetime_utc_moment : m.datetime_utc_moment;

            const hasDate = !m.isDaySame(previousDate);
            const hasProfile = previousSender === "" || m.nickname !== previousSender || hasDate || m.getMinuteDiff(previousDate) > 10;

            const serv = m.isImage ? props.server : undefined;

            let isSelected = false;
            if (m.id === selectedMessageId) {
              isSelected = true;
            }

            let item = <ChatItem key={i}
                                 index={i}
                                 server={serv}
                                 message={m}
                                 senderClass={senderClass}
                                 hasProfile={hasProfile}
                                 hasDate={hasDate}
                                 scrollIntoView={isSelected}
                                 selectedIdx={selectedMessageIdx}
                                 thisIdx={i} />;
            return item;

        }) }
      </div>

      {/* Typing animation */}
      { (isTyping || pausedTyping) &&
      <div className={`${styles.Typing}`}>
        <div>{typingUser} is typing</div>
        <div className={`${styles.Heart} ${pausedTyping ? "" : styles.AnimatedHeart}`}>{props.typingEmoji}</div>
      </div> }

      {/* Daily Message Count */}
      <div className={`${styles.MessageCount}`}>
        {messageCount}
      </div>

      {/* New Messages Overlay */}
      { newMsgCount > 0 &&
        <div className={styles.NewMessageOverlay}
             onClick={() => { if (moreMsgsNewer) { initMessages(); } else { windowRef.current?.scrollTo(0, 0); setNewMsgCount(0); setIsScrolledUp(false); } } }>
          {newMsgCount} New {newMsgCount === 1 ? "Message" : "Messages"}
        </div>
      }

      {/* Back To Bottom Overlay */}
      { (newMsgCount === 0 && (moreMsgsNewer || isScrolledUp)) &&
        <div className={styles.NewMessageOverlay}
             onClick={() => { if (moreMsgsNewer) { initMessages(); } else { windowRef.current?.scrollTo(0, 0); setIsScrolledUp(false); } } }>
          Back To Bottom
        </div>
      }
      
    </div>
  );

}

export default ChatWindow;
