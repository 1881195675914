import Message from '../../../Models/Message';
import styles from './SearchItem.module.css';

interface IProps {

  message: Message,
  showDateHeader: boolean,
  showContext: Function,

}

const SearchItem = (props: IProps) => {

  const message: Message = props.message;

  const formatMatch = (m: string) => {

    return m;

  }
  
  return (
    <div className={styles.Container}>
      { props.showDateHeader && <div className={styles.DateHeader}>{message.getDate()}</div> }
      <div className={styles.SearchItem} onClick={() => { props.showContext(message) }}>
        <div className={styles.Content}>
          <span className={styles.ResultSender}>{message.nickname}</span><br />
          <span className={styles.ResultText}>{formatMatch(message.content)}</span>
        </div>
        <div className={styles.TimeStamp}>{message.getTime()}</div>
      </div>

    </div>
  )
};

export default SearchItem;
