import React, { useState, useCallback, useEffect } from 'react';
import { debounce } from 'lodash';
import Message from '../../../Models/Message';
import SearchItem from '../SearchItem/SearchItem';
import styles from './SearchWindow.module.css';
import emitter from '../../../Utils/emitter';

type User = {
  userId: string,
  name: string,
  nickname: string,
  isOnline: boolean,
}

type Image = {
  id: string,
  gfsId: string,
  filename: string,
  sender: string,
  date: Date,
  tags: Array<string>
}

interface IProps {

  server: string,
  socket: any,
  chatId: string,
  members: Array<User>,
  tags: Array<string>,
  closeSearch: Function,
  openGallery: Function,

}

const textFilter = "Text";
// const imageFilter = "Image"

const SearchWindow = (props: IProps) => {

  const closeSearch = props.closeSearch;

  // Text currently being searched
  const [searchText, setSearchText] = useState<string>("");
  // First 3 letters of the previous text search fetch request
  const [oldSearchTextBase, setOldSearchTextBase] = useState<string>("");
  // Messages fetched from the server matching the text provided
  const [matches, setMatches] = useState<Array<Message>>([]);
  // Messages matching the search text which are being displayed to the user
  const [usedMatches, setUsedMatches] = useState<Array<Message>>([]);
  // Messages matching the search text which are stored for when the user scrolls down
  const [storedMatches, setStoredMatches] = useState<Array<Message>>([]);
  // Flag for whether or not there are more stored messages
  const [moreTextMessages, setMoreTextMessages] = useState<boolean>(false);

  const imgsRef = React.useRef<HTMLDivElement>(null);
  const [endpoint, setEndpoint] = useState<string>("");
  const [images, setImages] = useState<Array<Image>>([]);
  const [imagePage, setImagePage] = useState<number>(2);
  const [imagesScroll, setImagesScroll] = useState<boolean>(false);
  const [moreImages, setMoreImages] = useState<boolean>(true);
  const [loadingImages, setLoadingImages] = useState<boolean>(false);
  const [allTags, setAllTags] = useState<Array<string>>(props.tags);

  const [imageSearchSender, setImageSearchSender] = useState<string>("");
  const [imageSearchTags, setImageSearchTags] = useState<Array<string>>([]);

  // Filters
  const [filter, setFilter] = useState<string>(textFilter);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const closeSearchWindow = () => { closeSearch(); }

  function getSearchItems(matches: Array<Message>) {

    let previousDate = "";
    return matches.map((m: Message, i: number) => {
  
      const showDateHeader = previousDate !== m.getDate();
      previousDate = m.getDate();
      return <SearchItem key={i} message={m} showContext={showMessageContext} showDateHeader={showDateHeader} />
  
    });
  
  }

  const searchMessages = useCallback((text: string) => {

    // No empty searches
    if (text.length < 3) { setMatches([]);  return; }

    let baseChanged = false;
    
    // Check if the search base string changed
    // If it has changed, mark the flag as true and save the new base
    if (text.length >= 3 && text.substring(0, 3) !== oldSearchTextBase) { baseChanged = true; setOldSearchTextBase(text.substring(0, 3)); }

    // If length is 3 fetch matches from the server
    if (baseChanged || text.length === 3 || matches.length === 0) {

      fetch(`${props.server}/messages/${props.chatId}/${text.substring(0, 3)}`)
      .then(r => r.json())
      .then(d => {
        
        const msgs: Array<Message> = [];
        d.forEach((m: any) => { msgs.push(new Message(m.sender, m.nickname, m.content, m.date, m._id, m.isImage)); })
        setMatches(msgs);
        if (msgs.length > 100) {

          setStoredMatches(msgs);
          setUsedMatches(msgs.slice(0, 100));
          setMoreTextMessages(true);

        } else { setUsedMatches(msgs); setMoreTextMessages(false); }
        
      });

    }
    // Otherwise, just filter the matches
    if (text.length > 3) {
      
      let msgs: Array<Message> = [];
      msgs = matches.filter(m => m.content.toLowerCase().includes(text))
      if (msgs.length > 100) {

        setStoredMatches(msgs);
        setUsedMatches(msgs.slice(0, 100));
        setMoreTextMessages(true);

      } else { setUsedMatches(msgs); setMoreTextMessages(false); }
    
    }

  }, [matches, oldSearchTextBase]);

  const loadMoreMessages = useCallback(() => {

    if (!moreTextMessages) { return; }
    
    const numUsed: number = usedMatches.length;
    const numStored: number = storedMatches.length;
    if (numStored - numUsed < 100) {

      setMoreTextMessages(false);
      setUsedMatches([...storedMatches]);
      
    } else {
      
      setUsedMatches([...usedMatches, ...storedMatches.slice(numUsed, numUsed + 100)])
      
    }

  }, [moreTextMessages, storedMatches, usedMatches]);

  const showMessageContext = (message: Message) => {
    props.socket.emit("showMessageContext", emitter({ chatId: props.chatId, messageId: message.id, messageDate: message.datetime_utc_moment })); closeSearchWindow();
  };

  const searchImages = useCallback(async () => {

    return;
    // Ensures the function does not run more than once at a time
    if (loadingImages) { return; }
    
    // No more images, no need to search
    if (!moreImages) { return; }

    setLoadingImages(true);

    // Get appropriate query according to search filters
    let query = "";

    // Search by sender and tags
    if (imageSearchSender !== "" && imageSearchTags.length > 0) {

      const tags = imageSearchTags.map((t) => t.replace("+", "%2b"));
      query = `sender=${imageSearchSender}&tags=${tags.reduce((prev, curr) => prev + "," + curr)}`

    // Search by sender only
    } else if (imageSearchSender !== "") {

      query = `sender=${imageSearchSender}`

    // Search by tags only
    } else if (imageSearchTags.length > 0) {

      const tags = imageSearchTags.map((t) => t.replace("+", "%2b"));
      query = `tags=${tags.reduce((prev, curr) => prev + "," + curr)}`

    }

    query += `&page=${imagePage}`
    let ep = `${props.server}/images/${props.chatId}?${query}`
    setEndpoint(ep);
    
    // Get image names
    const response = await (await fetch(ep)).json();
    // No images left to retrieve
    if (response.length === 0) {
      
      if (imagePage === 1) { setImages([]); }
      setMoreImages(false);
      setLoadingImages(false);
      return;
    
    }
    // No images left to retrieve after the current batch
    if (response.length < 6) { setMoreImages(false); }

    // First page, remove any older previous images
    if (imagePage === 1) { setImages([...response]); }
    // Otherwise, append to the end of the current list
    else { setImages([...images, ...response]); }
    setImagePage(i => i + 1);

    setLoadingImages(false);

  }, [loadingImages, moreImages, imageSearchSender, imageSearchTags, imagePage, images]);

  const changeSender = useCallback((sender) => { setImageSearchSender(sender); }, []);

  const addSearchTag = useCallback((tag) => { setImageSearchTags([...imageSearchTags, tag]); }, [imageSearchTags]);

  const removeSearchTag = useCallback((tag) => { setImageSearchTags(imageSearchTags.filter(t => t !== tag)); }, [imageSearchTags]);

  const openGallery = useCallback((index) => { props.openGallery(endpoint, images, index, allTags); }, [endpoint, images]);

  const updateMessageText = useCallback(debounce((text: string) => {

    searchMessages(text);

  }, 1000), []);

  // Debouncing search text
  useEffect(() => { updateMessageText(searchText); }, [searchText, updateMessageText]);

  // Tags updated
  useEffect(() => {

    props.socket.on("tagsUpdated", (r: { imageId: string, tags: Array<string>, newTags: Array<string> }) => {
      
      setAllTags([...r.newTags, ...allTags]);

      // Update image
      let newImages = [...images];
      let index = -1;
      const i = newImages.find((i, idx) => { if (i.id === r.imageId) { index = idx; return true; } return false; });
      if (i !== undefined && index !== -1) {

        let newImage = {...i};
        newImage.tags = r.tags;
        newImages[index] = {...newImage};
        setImages(newImages);

      }

    });

    return () => { props.socket.off("tagsUpdated"); }

  }, [allTags, images]);

  useEffect(() => { setMoreImages(true); setImagePage(1); }, [imageSearchSender, imageSearchTags])

  // useEffect(() => { filter === imageFilter && imagePage === 1 && searchImages(); }, [filter, imagePage, searchImages])
  
  return (
    <div className={styles.Container}>
      <div className={`${styles.SearchWindow}`}>
        <button onClick={closeSearchWindow} className={styles.CloseSearch}>X</button>

        {/* Text Search Input */}
        { filter === textFilter &&
          <div className={styles.TextInputContainer}>
            <input className={`${styles.Input}`}
                  ref={inputRef}
                  type="text"
                  value={searchText}
                  onChange={(event) => { setSearchText(event.target.value); }}
                  // onKeyDown={(e) => { if (e.key === "Enter") { searchMessages(searchText); } }}
                  placeholder="Search for a message..." />
          </div>
        }

        {/* Image Search Input */}
        {/* { filter === imageFilter && <div className={styles.ImageInputContainer}></div> } */}

        <hr />

        {/* Filters */}
        <div className={styles.FiltersContainer}>

          <div className={styles.NonLabelFilters}>
            {/* Content Filters */}
            <div className={`${styles.Filters}`}>
              <span className={styles.FilterHeader}>Content:</span>
              {/* Text Filter */}
              <input type="radio" value="Text" name="type" id="textFilter" defaultChecked onChange={() => setFilter(textFilter)} />
              <label htmlFor="textFilter">Text</label>
              {/* Image Filter */}
              {/* <input type="radio" value="Images" name="type" id="imageFilter" onChange={() => { setFilter(imageFilter); searchImages(); }} />
              <label htmlFor="imageFilter">Images</label> */}
            </div>

            {/* Image Sent By Filters */}
            {/* { filter === imageFilter &&
              <div className={styles.Filters}>
                <span className={styles.FilterHeader}>Sent By:</span>
                  <span>
                    <input type="radio" value="allUsers" name="sender" id="allUsersFilter" defaultChecked onChange={() => { changeSender(""); }} />
                    <label htmlFor="allUsersFilter">All</label>
                  </span>
                { props.members.map((u, i) => { 
                    return (
                      <span key={i}>
                        <input type="radio" value={`${u.name}`} name="sender" id={`${u.name}Filter`} onChange={() => { changeSender(u.userId); }} />
                        <label htmlFor={`${u.name}Filter`}>{u.name}</label>
                      </span>
                    )
                  })
                }
              </div>
            } */}
          </div>

          {/* Image Tag Filters */}
          {/* { filter === imageFilter &&
            <div className={styles.ImageTags}>
              <span className={styles.FilterHeader}>Tags:</span>
              { allTags.map((t, i) => {
                  return (
                    <span key={i}>
                      <input type="checkbox" value={`${t}`} id={`${t}Filter`} onChange={(e) => { e.target.checked ? addSearchTag(t) : removeSearchTag(t) }} />
                      <label htmlFor={`${t}Filter`}>{t}</label>
                    </span>
                  )
                })
              }
            </div>
          } */}

        </div>

        
        {/* Image */}

        {/* Text Search Results */}
        { (filter === textFilter && usedMatches && usedMatches.length > 0) &&
        <div className={styles.TextResults}
             onScroll={(e: any) => { e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight - 10 && loadMoreMessages(); }}>
          { getSearchItems(usedMatches) }
          {/* { moreTextMessages && <button onClick={loadMoreMessages}>Get More Messages</button> } */}
        </div> }

        {/* Image Search Results */}
        {/* { (filter === imageFilter && images.length > 0) &&
        <div id="window"
             ref={imgsRef}
             className={styles.ImageResults}
             onScroll={(e: any) => { e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight - 10 && searchImages(); }}>
          { images.map((img, i) => {

            const filename = img.filename;
            return (
              <div key={filename} id={filename} className={styles.ImageResult}>
                <img src={`${props.server}/image/${filename}`}
                     alt="#"
                     onLoad={(!imagesScroll && i === images.length - 1)
                      ? () => { if (imgsRef.current instanceof HTMLDivElement && imgsRef.current.clientHeight < imgsRef.current.scrollHeight) { setImagesScroll(true); searchImages(); } }
                      : undefined} />
                <div id={`${filename}-overlay`} className={styles.ImageOverlay} onClick={() => openGallery(i)}>
                  <span style={{backgroundColor: "black", padding: "2px 5px", borderRadius: "10px", }}>Fullscreen</span>
                </div>
              </div>
            );
            
          })}
          { ( !loadingImages && moreImages && !imagesScroll) && <button onClick={() => searchImages()} style={{flexBasis: "90%"}}>Show More</button> }
        </div>
        } */}

      </div>
    </div>
  )
};

export default SearchWindow;
